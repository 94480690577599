import { Center, Flex, useColorMode, Link, Text, Spacer, Button, Stack } from '@chakra-ui/react'
import React from 'react'
import useColor from '@hooks/useColor'
import LoginForm from '../LoginForm'
import Logo from './Logo'

const LoginPage: React.FC = () => {
  const { colorMode } = useColorMode()
  const sidebarBackground = useColor('sidebarBackground')
  return (
    <Flex bg={sidebarBackground} flexGrow={1} overflow='auto' flexDirection='column'>
      <Center flexGrow={1.5}>
        <Logo size='lg' highlightColor='highlight' lightDark={colorMode} forceShowText={true} />
      </Center>

      <Center flexGrow={2}>
        <LoginForm />
      </Center>
      <Center flexGrow={0.5}>
        <Link href='/reset'>Reset Password</Link>
      </Center>

      <Center flexGrow={1.5}>
        <Stack>
          <Text textAlign='center' fontSize='sm'>
            Dont have access?
          </Text>
          <Link fontSize='xs' href='https://missionbase.com/'>
            <Button size='lg' variant='outline' height='44px' p={4}>
              Request Beta Access
            </Button>
          </Link>
        </Stack>
      </Center>

      <Spacer />

      <Flex p={2} sx={{ a: { _hover: { textDecoration: 'underline' } } }}>
        <Text fontSize='xs' mr={3}>
          © {new Date().getFullYear()} Mission Base
        </Text>
        <Link fontSize='xs' mr={3} href='#'>
          Privacy Policy
        </Link>
      </Flex>
    </Flex>
  )
}

export default LoginPage
