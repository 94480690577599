import React from 'react'
import { Center, Text } from '@chakra-ui/react'
// import Span from '../../Reusables/Structure/Span'
// import { NavDataContext } from '../../../contexts/NavDataContext'
import { LogoProps } from './types'
import Icon from '@Reusables/Design/Icon'
import useColor from '@hooks/useColor'

/**
 * This component will render the MissionBase logo
 * @param highlightColor -
 * @param lightDark - force logo to light or dark
 * @param size - adjust the size of the logo
 * @param forceShowText - Show the text even if on narrow display
 */
const Logo: React.FC<LogoProps> = ({ size = 'md', forceShowText = false, lightDark }) => {
  const textSize = size === 'md' ? 18 : size === 'lg' ? 24 : size === 'sm' ? 15 : 18
  const display = forceShowText ? ['inline'] : ['none', 'inline']
  const logoColor = useColor('logoColor', lightDark)
  return (
    <Center>
      <Icon name='rocket' source='custom' color={logoColor} size={6} mx={1} />
      <Center>
        <Text as='span' fontSize={textSize} textDecoration='none' display={display} color={logoColor} fontWeight='logo'>
          mission
          <Text as='span' fontWeight='bold'>
            base
          </Text>
        </Text>
      </Center>
    </Center>
  )
}

export default Logo
