import React, { useState, useRef } from 'react'
import { useMutation, gql } from '@apollo/client'
import { Input, Button, Center, useToast, Stack, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useUserContext } from '@contexts/UserContext'
import { Mutation } from '@localTypes/GraphQL'
// import { useSession } from 'next-auth/react'

const AUTHENTICATE2 = gql`
  mutation AUTHENTICATE2($username: String!, $password: String!) {
    Authenticate(username: $username, pass: $password) {
      token
      expires
      username
      admin
    }
  }
`

const LoginForm: React.FC = () => {
  // const [session, loading] = useSession()
  const user = useUserContext()
  const { setLogin, isLoggedIn } = user
  const toast = useToast()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  const inputRef = useRef(null)
  const [login, response] = useMutation<Mutation>(AUTHENTICATE2, {
    variables: { username, password },
    onCompleted: (results) => console.log({ ...results }),
    onError: (_err) => {
      console.error(_err)
      toast({
        title: 'Invalid Credentials.',
        description: 'Unable to Process Login.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      })
    },
  })
  if (response.data?.Authenticate?.token) {
    setLogin(
      response.data.Authenticate.token,
      response.data.Authenticate.username,
      response.data.Authenticate.expires,
      response.data.Authenticate?.admin
    )
  }

  if (isLoggedIn) return null

  return (
    <Stack spacing={12} flexGrow={0.1}>
      {/* <Text textAlign='center'>Please Log In</Text> */}
      {/* {!session && (
        <>
          Not signed in <br />
          <button onClick={() => signIn()}>Sign in</button>
        </>
      )}
      {session && (
        <>
          Signed in as {session.user.email} <br />
          <button onClick={() => signOut()}>Sign out</button>
        </>
      )} */}
      <Stack>
        <InputGroup size='lg'>
          <Input
            variant='filled'
            ref={inputRef}
            value={username}
            p={4}
            // mt={1.5}
            type='text'
            placeholder='john.doe'
            onChange={(e) => {
              setUsername(e.target.value)
            }}
          />
        </InputGroup>
        <InputGroup size='lg'>
          <Input
            variant='filled'
            isRequired={true}
            value={password}
            p={4}
            // mt={1.5}
            type={show ? 'text' : 'password'}
            placeholder='password'
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && username && password && !response.loading) {
                e.preventDefault()
                login()
              }
            }}
          />
          <InputRightElement width='4.5rem' pt={1.5}>
            <Button h='1.75rem' size='sm' onClick={handleClick} px={1.5}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
      </Stack>
      <Center>
        <Button isDisabled={response.loading || !password || !username} onClick={() => login()} height='44px' p={4} size='lg'>
          Log in
        </Button>
      </Center>
    </Stack>
  )
}

export default LoginForm
