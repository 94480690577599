import LoginPage from '@components/MainHeader/LoginPage'
import Main from '@Marketing/Main'

const Login = ({ title, description }) => {
  return (
    <Main pageTitle={`${title} | About`} description={description}>
      <LoginPage />
    </Main>
  )
}

export default Login

export async function getStaticProps() {
  const configData = await import(`@Marketing/siteconfig.json`)

  return {
    props: {
      title: configData.default.title,
      description: configData.default.description,
    },
  }
}
